/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  Select,
  Switch,
  DatePicker,
  Space,
  Typography,
  Button,
  Tag,
  Table,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "../index.css";
import LoaderComp from "../Utility/LoaderComp";
import { useCookies } from "react-cookie";
import { destroyToken } from "../API/User";
import getLogo from "../helpers/getLogo";
import getCurrencySymbol from "../helpers/getCurrencySymbol";
import selectCountryOptions from "../helpers/selectCountryOptions";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const createDate = (today) => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return [dd, mm, yyyy];
};

function QuiztwizDash(props) {
  const [userName, setUserName] = useState("");
  const [cookies, , removeCookie] = useCookies(["my_token"]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchDate, setSearchDate] = useState(0);

  const navigate = useNavigate();

  const [totalPv, setTotalPv] = useState("");
  const [totalRev, setTotalRev] = useState("");
  const [averageRpm, setAverageRpm] = useState("");
  const [revGraph, setRevGraph] = useState([]);
  const [pvGraph, setPvGraph] = useState([]);
  const [rpmGraph, setRpmGraph] = useState([]);
  const [dateRangeVal, setDateRangeVal] = useState([null, null]);
  const [quickDate, setQuickDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("All Countries");
  const [timezone, setTimezone] = useState("IST");
  const retryCountRef = useRef(0);
  const intervalRef = useRef(null);
  // const [selectCountryOptions, setSelectCountryOptions] = useState([]);
  const [geoToggle, setGeoToggle] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(["All Channels"]);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectChannelOptions, setSelectChannelOptions] = useState([]);
  const [currToggleShow, setCurrToggleShow] = useState(false);
  const [clientCurrency, setClientCurrency] = useState(["USD", "INR"]);
  const [currConvertToggle, setCurrConvertToggle] = useState(false);
  const [token, setToken] = useState(false);
  const [isUnibots, setIsUnibots] = useState(true);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Channel Name",
      dataIndex: "channelName",
      key: "channelName",
      width: "20vw",
    },
    {
      title: "Country",
      dataIndex: "countryName",
      key: "countryName",
      sorter: (a, b) => a.countryName > b.countryName,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: `Estimated Earnings (${
        currConvertToggle ? `${clientCurrency[1]}` : `${clientCurrency[0]}`
      })`,
      dataIndex: "rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.rev)
          ? 0
          : getCurrencySymbol(
              currConvertToggle ? clientCurrency[1] : clientCurrency[0]
            ) + Number(record.rev).toFixed(2);
      },
      // width: 100,
      sorter: (a, b) => a.rev - b.rev,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Page Views",
      dataIndex: "pv",
      key: "pv",
      sorter: (a, b) => a.pv - b.pv,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.pv) ? "NA" : record.pv;
      },
    },
    {
      title: "RPM",
      dataIndex: "pv_rpm",
      key: "pv_rpm",
      sorter: (a, b) => a.pv_rpm - b.pv_rpm,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return isNaN(record.pv_rpm)
          ? "NA"
          : // : getCurrencySymbol() + Number(record.pv_rpm).toFixed(2);
            getCurrencySymbol(
              currConvertToggle ? clientCurrency[1] : clientCurrency[0]
            ) + Number(record.pv_rpm).toFixed(2);
      },
    },
  ];

  const errorMessage = (type) => {
    messageApi.open({
      type: "warning",
      content: "Failed to fetch client data, please refresh!",
      duration: 5,
    });
  };

  // async function getSelectCountryOptions() {
  //   const res = await axios.get(
  //     `${process.env.REACT_APP_GAMDASH_CDN}/selectCountryOptions.json`
  //   );
  //   setSelectCountryOptions(res.data);
  // }

  // function filterTableColumns() {
  //   const col = columns.filter((obj) => {
  //     return !geoToggle ? obj.title !== "Country" : obj;
  //   });
  //   return col;
  // }

  function filterTableColumns() {
    return columns.filter((obj) => {
      // Exclude columns based on the given conditions
      if (
        (!geoToggle && obj.title === "Country") ||
        (selectedChannel.includes("combined") && obj.title === "Channel Name")
      ) {
        return false;
      }
      return true; // Include all other columns
    });
  }

  useEffect(() => {
    const my_token = cookies.my_token;
    setToken(my_token.split(".").slice(0, 3).join("."));
    let userData = {};
    // Extract user data from the cookie
    if (my_token) {
      userData = JSON.parse(atob(my_token.split(".")[3]));
      setToken(my_token.split(".").slice(0, 3).join("."));
    }

    setUserName(userData?.name);
    setTimezone(userData?.timezone);

    // getSelectCountryOptions();
  }, [cookies]);

  useEffect(() => {
    const currentUrl = window.location.href;
    setIsUnibots(!currentUrl.includes("revcompass"));
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    var d = createDate(today);
    const d1 = d[2] + "-" + d[1] + "-" + d[0];
    d = createDate(firstDay);
    const d2 = d[2] + "-" + d[1] + "-" + d[0];
    setDateRangeVal([d2, d1]);

    setSearchDate(!searchDate);

    async function accountCheck() {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ADSENSE_URL}/client_data`,
          {
            data: {
              clientName: userName,
              type: "QUIZTWIZ",
            },
          },
          {
            headers: {
              // AuthKey: process.env.REACT_APP_CLIENTDATA_AUTHKEY,
              Authorization: token,
            },
          }
        );

        if (res.data?.data?.currency_conversion?.exists) {
          setCurrToggleShow(true);
          setClientCurrency(res.data?.data?.currency_conversion?.value);
        }

        setSelectChannelOptions(
          res.data?.data.quiztwiz[timezone.toLowerCase()]
        );
      } catch (error) {
        errorMessage();
        console.error("An error occurred during account check:", error.message);
      }
    }
    if (userName) accountCheck();
  }, [userName]);

  useEffect(() => {
    if (
      dateRangeVal[0] != null &&
      dateRangeVal[1] != null &&
      searchDate !== 0 &&
      selectChannelOptions.length !== 0
    ) {
      const getData = async () => {
        setLoadingScreen(true);
        setTableData([]);
        let res;

        const requestBody = {
          clientName: userName,
          dateStart: dateRangeVal[0],
          dateEnd: dateRangeVal[1],
          countryName: [selectedCountry],
          geodata: geoToggle,
          timezone: timezone,
          aggregate_data: true,
          channelName: selectedChannel,
          currencyCode: !currConvertToggle
            ? clientCurrency[0]
            : clientCurrency[1],
        };

        // TEMP CONDITION FOR unibotstest
        // if (userName === "unibotstest")
        //   requestBody.channelName = selectedChannel;

        try {
          res = await axios.post(
            `${process.env.REACT_APP_ADSENSE_URL}/quiztwiz_data/fetch`,
            {
              data: requestBody,
            },
            {
              headers: {
                // AuthKey: `${process.env.REACT_APP_AUTHKEY}`,
                Authorization: token,
              },
            }
          );
          res.data = res.data.response;
          setTableData(res.data.data);
          setTotalPv(res.data.pv);
          setTotalRev(res.data.rev);
          setAverageRpm(res.data.pv_rpm);
          setPvGraph(res.data.pvGraph);
          setRevGraph(res.data.revGraph);
          setRpmGraph(res.data.pv_rpmGraph);
          // setRevShare(res.data?.revShare);

          // setTimezone(res.data.timezone.toUpperCase());
          // if (res.data.timezone.toLowerCase() === "account") setTimezone("IST");
          // else setTimezone("PST");

          setLoadingScreen(false);
        } catch (err) {
          // Handle error and retry logic
          console.error("Request failed:", err.message);

          if (Object.keys(cookies).length === 0) retryCountRef.current = 2;

          if (retryCountRef.current < 2) {
            // Retry the request after a delay (e.g., 5 seconds)
            setTimeout(() => {
              retryCountRef.current += 1;
              getData();
            }, 5000);
          } else {
            errorMessage();
            console.error("Max retry count reached. Unable to fetch data.");
          }
        }
      };
      getData();

      setFilteredColumns(filterTableColumns());

      intervalRef.current = setInterval(() => {
        getData();
      }, 900000);

      // Cleanup function to cancel the timers if the component unmounts or if the user navigates away
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [
    searchDate,
    selectedCountry,
    geoToggle,
    selectedChannel,
    currConvertToggle,
    selectChannelOptions,
  ]);

  const handleQuickDateChange = (days) => {
    const today = new Date();
    const d1 = createDate(today);

    const startDate = new Date(today);
    startDate.setDate(today.getDate() - (days - 1));
    const d2 = createDate(startDate);

    const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
    const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

    setDateRangeVal([date1, date2]);
    setSearchDate(!searchDate);
    setQuickDate(`${days}D`);
  };

  const flattenData = (data) => {
    const result = [];
    const flatten = (record, parentKey) => {
      const { key, ...rest } = record;
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      if (geoToggle)
        result.push({
          key: currentKey,
          Date: rest.date,
          // "Page Views": rest.pv,
          "Channel Name": rest.channelName,
          Country: rest.countryName,
          "Estimated Earnings": rest.rev,
          "Page Views": rest.pv,
          RPM: rest.pv_rpm,
          // RPM: rest.rpm,
          // "Fill Rate": rest.fillrate,
        });
      else
        result.push({
          key: currentKey,
          Date: rest.date,
          // "Page Views": rest.pv,
          "Channel Name": rest.channelName,
          "Estimated Earnings": rest.rev,
          "Page Views": rest.pv,
          RPM: rest.pv_rpm,
          // RPM: rest.rpm,
          // "Fill Rate": rest.fillrate,
        });
    };
    data.forEach((record) => flatten(record));
    result.push({
      key: 100001,
      "Channel Name": "Total",
      "Estimated Earnings": totalRev,
      "Page Views": totalPv,
      RPM: averageRpm,
    });
    return result;
  };

  const flattenedData = flattenData(tableData).map((record) => {
    delete record.key;
    delete record.name;
    return record;
  });

  const handleLogout = () => {
    removeCookie("my_token");
    destroyToken(token, userName);
    navigate("/login");
  };

  const CustomTooltip = ({ active, payload, label, coordinate, viewBox }) => {
    if (active && payload && payload.length) {
      const chartMidPoint = (viewBox.width * 2) / 3;
      const isRightHalf = coordinate.x > chartMidPoint;

      return (
        <div
          className="recharts-default-tooltip"
          style={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            paddingLeft: "10px",
            paddingRight: "10px",
            left: isRightHalf ? coordinate.x - 95 : coordinate.x + 10,
          }}
        >
          <p className="recharts-tooltip-label mt-2 mb-1">{label}</p>
          {payload.map((entry, index) => (
            <p
              key={`item-${index}`}
              style={{ color: entry.color, marginTop: 0 }}
            >
              {`${entry.name} : ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {loadingScreen ? (
        <>
          {contextHolder}
          <LoaderComp fullScreen={true} />
        </>
      ) : (
        <div className="w-full">
          {contextHolder}
          <div className="sm:px-2 md:px-4 lg:px-6">
            <div
              className={`grid gap-1 grid-cols-1 lg:grid-cols-5 justify-between items-center my-4`}
            >
              <div className="flex justify-center">{getLogo(isUnibots)}</div>

              <div className="flex justify-center lg:col-span-2">
                <div className="flex items-center my-3">
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      value={
                        dateRangeVal[0] !== "" && dateRangeVal[1] !== ""
                          ? [dayjs(dateRangeVal[0]), dayjs(dateRangeVal[1])]
                          : // [dayjs.utc(dateRangeVal[0]), dayjs.utc(dateRangeVal[1])]
                            undefined
                      }
                      onChange={(date, dateString) => {
                        setDateRangeVal(dateString);
                      }}
                    />
                  </Space>
                  <Button
                    style={{ marginLeft: 4 }}
                    type="primary"
                    onClick={() => setSearchDate(searchDate + 1)}
                  >
                    Search
                  </Button>
                </div>
              </div>

              <div className="flex justify-center">
                <Button
                  onClick={() => handleQuickDateChange(1)}
                  type={quickDate === "1D" ? "primary" : "default"}
                >
                  24H
                </Button>

                <Button
                  onClick={() => handleQuickDateChange(7)}
                  type={quickDate === "7D" ? "primary" : "default"}
                >
                  7D
                </Button>

                <Button
                  onClick={() => handleQuickDateChange(30)}
                  type={quickDate === "30D" ? "primary" : "default"}
                >
                  30D
                </Button>
              </div>

              <div className="flex justify-center">
                <Button
                  danger
                  type="text"
                  size="large"
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </div>
            <div>
              <div>
                <div
                  className={`grid grid-cols-1 ${
                    geoToggle ? "lg:grid-cols-4" : "lg:grid-cols-3"
                  } justify-between items-center my-4 h-auto lg:h-72`}
                >
                  {geoToggle && (
                    // && geoEnable.includes(userName)
                    <div>
                      <div className="flex align-top justify-center pb-4  ">
                        <Select
                          showSearch
                          style={{ width: "80%", maxWidth: 420 }}
                          placeholder="Select Country"
                          options={selectCountryOptions}
                          value={selectedCountry}
                          onChange={(val) => {
                            setSelectedCountry(val);
                          }}
                        ></Select>
                      </div>
                      <div className="flex align-top justify-center pb-4">
                        <Select
                          // mode="multiple"
                          showSearch
                          style={{
                            width: "80%",
                            maxWidth: 420,
                            maxHeight: 120,
                            overflowY: "auto",
                          }}
                          placeholder="Select Channel"
                          options={selectChannelOptions}
                          filterOption={(input, option) =>
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.id
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          value={selectedChannel}
                          onChange={(val, arr) => {
                            setSelectedChannel([val]);
                          }}
                        ></Select>
                      </div>
                    </div>
                  )}
                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={pvGraph}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorImp"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#2e7d32"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#2e7d32"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          {/* <Tooltip /> */}
                          <Tooltip content={<CustomTooltip />} />
                          <Area
                            type="monotone"
                            dataKey="pv"
                            stroke="#2e7d32"
                            fillOpacity={1}
                            fill="url(#colorImp)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="green"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`Page Views: ${totalPv}`}
                      </Tag>
                    </div>
                  </div>

                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={revGraph}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorRev"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#1976d2"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#1976d2"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>

                          <XAxis
                            dataKey="name"
                            style={{ marginLeft: "50px" }}
                          />
                          <YAxis />
                          {/* <Tooltip /> */}
                          <Tooltip content={<CustomTooltip />} />
                          <Area
                            type="monotone"
                            dataKey="Earning"
                            stroke="#1976d2"
                            fillOpacity={1}
                            fill="url(#colorRev)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="blue"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        Revenue:{" "}
                        {getCurrencySymbol(
                          currConvertToggle
                            ? clientCurrency[1]
                            : clientCurrency[0]
                        )}
                        {totalRev}
                      </Tag>
                    </div>
                  </div>
                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={rpmGraph}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorRpm"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#e83462"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#e83462"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          {/* <Tooltip /> */}
                          <Tooltip content={<CustomTooltip />} />
                          <Area
                            type="monotone"
                            dataKey="pv_rpm"
                            stroke="#e83462"
                            fillOpacity={1}
                            fill="url(#colorRpm)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="error"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`Page Views RPM: ${averageRpm}`}
                      </Tag>
                    </div>
                  </div>
                </div>

                <div
                  className={`grid grid-cols-2 ${
                    currToggleShow ? "lg:grid-cols-4" : "grid-cols-3"
                  } mb-2 px-2`}
                >
                  <div className="flex space-x-2 justify-center my-2 lg:my-0">
                    <Switch
                      // value={geoToggle}
                      checked={geoToggle}
                      onChange={() => {
                        setSelectedChannel(["All Channels"]);
                        setGeoToggle(!geoToggle);
                      }}
                    />
                    <Text strong>{geoToggle ? "GeoWise" : "Normal"}</Text>
                  </div>
                  {currToggleShow && (
                    <div className="flex space-x-2 justify-center my-2 lg:my-0">
                      <Switch
                        // value={geoToggle}
                        checked={currConvertToggle}
                        onChange={() =>
                          setCurrConvertToggle(!currConvertToggle)
                        }
                      />
                      <Text strong>
                        {currConvertToggle
                          ? `Currency: ${clientCurrency[1]}`
                          : `Currency: ${clientCurrency[0]}`}
                      </Text>
                    </div>
                  )}
                  <div className="flex justify-center my-2 lg:my-0">
                    <Text keyboard strong>
                      {`Timezone: ${timezone}`}
                    </Text>
                  </div>
                  <div className="flex justify-center my-2 lg:my-0">
                    <Button
                      type="primary"
                      size="small"
                      style={{
                        backgroundColor: "#1B5E20",
                        borderColor: "#1B5E20",
                      }}
                    >
                      <CSVLink
                        filename={"DataTable.csv"}
                        data={flattenedData}
                        className="btn btn-primary"
                        style={{ textDecoration: "none" }} // Remove underline from text
                      >
                        <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                          <span style={{ color: "#FFFFFF" }}>
                            Export to CSV
                          </span>
                        </Typography>
                      </CSVLink>
                    </Button>
                  </div>
                </div>
                <div className="flex">
                  <Table
                    bordered
                    columns={filteredColumns}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                    dataSource={tableData}
                    scroll={{
                      x: 1000,
                      y: 850,
                    }}
                    summary={() => {
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                              <Text strong>Total</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            {geoToggle && (
                              <Table.Summary.Cell
                                index={2}
                              ></Table.Summary.Cell>
                            )}

                            <Table.Summary.Cell index={geoToggle ? 3 : 2}>
                              <Text strong>
                                {getCurrencySymbol(
                                  currConvertToggle
                                    ? clientCurrency[1]
                                    : clientCurrency[0]
                                )}
                                {totalRev}
                                {/* {getCurrencySymbol() + totalRev} */}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={geoToggle ? 4 : 3}>
                              <Text strong>{totalPv}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={geoToggle ? 5 : 4}>
                              <Text strong>
                                {getCurrencySymbol(
                                  currConvertToggle
                                    ? clientCurrency[1]
                                    : clientCurrency[0]
                                ) + Number(averageRpm).toFixed(2)}{" "}
                                <span
                                  style={{
                                    fontSize: "0.8em",
                                    color: "#a9a9a9",
                                  }}
                                >
                                  avg
                                </span>
                                {/* {getCurrencySymbol() + averageRpm}{" "}
                                <span
                                  style={{
                                    fontSize: "0.8em",
                                    color: "#a9a9a9",
                                  }}
                                >
                                  avg
                                </span> */}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default QuiztwizDash;
