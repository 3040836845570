function getLogo(isUnibots) {
  return isUnibots ? (
    <svg
      width="180"
      height="80"
      viewBox="0 0 1352 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 144.371V56.0952L21.633 42.3667V133.595L70.9326 159.724V186L0 144.371Z"
        fill="black"
      />
      <path
        d="M166 144.371V56.0952L144.367 42.3667V133.595L95.0674 159.724V186L166 144.371Z"
        fill="black"
      />
      <path
        d="M127.738 51.3714L144.514 42.3667V69.8238L116.112 88.7191L127.738 51.3714Z"
        fill="black"
      />
      <path
        d="M70.9326 159.724V32.0789V7.52857L49.8883 19.781V133.595L70.9326 159.724Z"
        fill="black"
      />
      <path
        d="M95.0674 159.724V32.0789V7.52857L116.112 19.781V133.595L95.0674 159.724Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.112 19.781L95.0674 7.52857V32.0789L116.112 44.2577V19.781ZM70.9326 32.0789V7.52857L49.8883 19.781V44.2577L70.9326 32.0789Z"
        fill="black"
      />
      <path
        d="M414 56H396V129.8H306V56H288V129.8C288 129.8 288 146 304.2 146H397.8C414.54 146 414 129.8 414 129.8V56Z"
        fill="black"
      />
      <path
        d="M468.07 56V146H486.07V77.6L567.07 146H585.07V56H567.07V124.4L486.07 56H468.07Z"
        fill="black"
      />
      <path d="M657 56H639V146H657V56Z" fill="black" />
      <path
        d="M710.965 56V146H820.765C836.965 146 836.965 129.8 836.965 129.8V106.4C836.965 101 831.565 101 831.565 101C836.965 101 836.965 95.6 836.965 95.6V72.2C836.965 56 820.765 56 820.765 56H710.965ZM818.965 92H728.965V72.2H818.965V92ZM818.965 129.8H728.965V108.2H818.965V129.8Z"
        fill="black"
      />
      <path
        d="M999.035 56H909.035C891.035 56 891.035 72.2 891.035 72.2V129.8C891.035 129.8 891.035 146 909.035 146H999.035C1017.04 146 1017.04 129.8 1017.04 129.8V72.2C1017.04 72.2 1017.04 56 999.035 56ZM999.035 72.2V129.8H909.035V72.2H999.035Z"
        fill="black"
      />
      <path
        d="M1184.51 56H1058.51V72.2H1112.51V146H1130.51V72.2H1184.51V56Z"
        fill="black"
      />
      <path
        d="M1333.86 92H1243.86V72.2H1350.06V56H1243.86C1225.86 56 1225.86 72.2 1225.86 72.2V92C1225.86 92 1225.86 108.2 1243.86 108.2H1333.86V129.8H1225.86V146H1333.86C1351.86 146 1351.86 129.8 1351.86 129.8V108.2C1351.86 108.2 1351.86 92 1333.86 92Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      width="180"
      height="80"
      viewBox="0 0 768 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.5552 82.295L24.1089 47.3992H7.27815V82.295H0V1.70501H28.0891C42.5317 1.70501 52.0842 11.7077 52.0842 24.6658C52.0842 36.3735 44.2375 45.3532 31.9556 46.9445L51.9705 82.295H43.5552ZM7.27815 40.9202H26.9519C37.6417 40.9202 44.8061 34.4411 44.8061 24.6658C44.8061 14.7767 37.6417 8.18404 26.9519 8.18404H7.27815V40.9202Z"
        fill="black"
      />
      <path
        d="M117.278 82.295H69.743V1.70501H117.278V8.2977H77.0211V38.6468H113.753V45.2395H77.0211V75.7023H117.278V82.295Z"
        fill="black"
      />
      <path
        d="M160.969 73.6563L187.808 1.70501H195.427L164.381 82.295H157.217L126.626 1.70501H134.359L160.969 73.6563Z"
        fill="black"
      />
      <path
        d="M244.131 84C220.932 84 202.281 67.2909 202.281 42.1705C202.281 17.0501 221.386 0 243.903 0C268.126 0 278.816 15.5724 281.431 27.1664L264.487 32.5088C263.236 26.9391 258.118 17.5047 243.903 17.5047C233.1 17.5047 220.932 25.2341 220.932 42.1705C220.932 57.2882 232.076 66.0406 244.131 66.0406C258.118 66.0406 263.691 56.7199 265.283 50.9229L282.455 55.8106C279.839 66.9499 269.149 84 244.131 84Z"
        fill="black"
      />
      <path
        d="M308.11 41.9432C308.11 58.7659 320.165 66.2679 331.423 66.2679C342.568 66.2679 354.622 58.7659 354.622 41.9432C354.622 25.1204 342.568 17.5047 331.423 17.5047C320.165 17.5047 308.11 25.1204 308.11 41.9432ZM289.46 42.0568C289.46 16.4817 308.679 0 331.423 0C354.054 0 373.272 16.4817 373.272 42.0568C373.272 67.5183 354.054 84 331.423 84C308.679 84 289.46 67.5183 289.46 42.0568Z"
        fill="black"
      />
      <path
        d="M477.395 82.295H459.768V28.8714L438.73 82.295H424.173L403.135 29.5535V82.295H386.077V1.70501H409.503L431.679 56.1516L452.717 1.70501H477.395V82.295Z"
        fill="black"
      />
      <path
        d="M512.993 37.8511H523.455C530.279 37.8511 534.941 33.8728 534.941 27.6211C534.941 21.1421 530.279 17.2774 523.455 17.2774H512.993V37.8511ZM525.275 53.3099H512.879V82.295H494.911V1.70501H525.275C541.651 1.70501 552.909 12.5034 552.909 27.5074C552.909 42.7388 541.651 53.3099 525.275 53.3099Z"
        fill="black"
      />
      <path
        d="M612.342 82.295L606.542 65.9269H575.382L569.583 82.295H550.705L581.068 1.70501H601.879L631.902 82.295H612.342ZM591.19 21.7104L581.296 49.5589H600.856L591.19 21.7104Z"
        fill="black"
      />
      <path
        d="M697.223 21.4831L681.074 26.0298C680.506 22.2788 677.435 15.9134 667.655 15.9134C660.946 15.9134 656.624 20.1191 656.624 24.5521C656.624 28.4168 659.013 31.2585 664.585 32.2815L675.388 34.3275C690.741 37.1691 698.701 47.0582 698.701 58.5386C698.701 71.1556 688.125 84 668.906 84C646.617 84 637.064 69.5643 635.927 58.1976L652.417 54.2192C653.099 61.6076 658.103 68.0866 669.134 68.0866C676.185 68.0866 680.506 64.6766 680.506 59.6752C680.506 55.5832 677.208 52.6279 671.863 51.6049L660.832 49.5589C647.186 46.9445 638.884 37.8511 638.884 25.9161C638.884 10.6847 652.303 0 667.769 0C687.898 0 695.517 12.0487 697.223 21.4831Z"
        fill="black"
      />
      <path
        d="M766.522 21.4831L750.373 26.0298C749.805 22.2788 746.734 15.9134 736.954 15.9134C730.245 15.9134 725.923 20.1191 725.923 24.5521C725.923 28.4168 728.311 31.2585 733.884 32.2815L744.687 34.3275C760.04 37.1691 768 47.0582 768 58.5386C768 71.1556 757.424 84 738.205 84C715.916 84 706.363 69.5643 705.226 58.1976L721.716 54.2192C722.398 61.6076 727.402 68.0866 738.433 68.0866C745.483 68.0866 749.805 64.6766 749.805 59.6752C749.805 55.5832 746.507 52.6279 741.162 51.6049L730.131 49.5589C716.484 46.9445 708.183 37.8511 708.183 25.9161C708.183 10.6847 721.602 0 737.068 0C757.196 0 764.816 12.0487 766.522 21.4831Z"
        fill="black"
      />
    </svg>
  );
}

export default getLogo;
