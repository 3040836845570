import UAParser from "ua-parser-js";
import axios from "axios";

const getUserInfo = async () => {
  try {
    const parser = new UAParser();
    const userAgent = window.navigator.userAgent;
    const userDevice = parser.getDevice();
    const userBrowser = parser.getBrowser();

    let userinfo = {};
    // Browser
    if (userBrowser && userBrowser.name && userBrowser.version) {
      userinfo["browserVersion"] = `${userBrowser.name} ${userBrowser.version}`;
    }
    // Device Type
    if (userDevice && userDevice.type) {
      userinfo["deviceType"] = userDevice.type === "mobile" ? "Mobile" : "PC";
    } else {
      if (/Mobi|Android/i.test(userAgent)) {
        userinfo["deviceType"] = "Mobile";
      } else {
        userinfo["deviceType"] = "PC";
      }
    }
    const API_URL = process.env.REACT_APP_PRO_IP_API_URL;
    const ipdata = await axios.get(API_URL);
    userinfo["ip"] = ipdata.data;
    return userinfo;
  } catch (error) {
    console.error("Error fetching user info:", error);
    return { error: "Unable to fetch user info" };
  }
};

export default getUserInfo;
