import { Routes, Route } from "react-router-dom";
import AFSDash from "./Screens/AFSDash";
import Login from "./Auth/Login";
import ProtectedRoute from "./Screens/ProtectedRoute";
import Quiztwizdash from "./Screens/Quiztwizdash";
import Adsensedash from "./Screens/AdsenseDash";
import Afsadmin from "./Screens/Afsadmin";

function App() {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/afs"
          element={
            <ProtectedRoute>
              <AFSDash />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/quiztwiz"
          element={
            <ProtectedRoute>
              <Quiztwizdash />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/adsense"
          element={
            <ProtectedRoute>
              <Adsensedash />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/afsadmin"
          element={
            <ProtectedRoute>
              <Afsadmin />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}
export default App;
