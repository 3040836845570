import axios from "./axiosInstance";

const createUser = async (userData) => {
  try {
    const res = await axios({
      url: "user/create_user",
      method: "POST",
      data: {
        username: userData.username,
        password: userData.password,
        type: userData.type,
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const verifyUser = async (userData) => {
  try {
    const res = await axios({
      url: "user/login",
      method: "POST",
      data: {
        username: userData.username,
        password: userData.password,
        userinfo: userData.userinfo,
      },
    });
    return { data: res.data, err: false };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const authUser = async (key) => {
  try {
    const res = await axios({
      url: "user/auth",
      method: "POST",
      data: {
        token: key,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const destroyToken = async (token, userName) => {
  try {
    const res = await axios({
      url: "user/logout",
      method: "POST",
      headers: {
        Authorization: token,
      },
      data: {
        data: {
          clientName: userName,
        },
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

export { createUser, verifyUser, authUser, destroyToken };
