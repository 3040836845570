import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Typography,
  Form,
  Input,
  Select,
  Modal,
  Switch,
  Spin,
} from "antd";
import { CloseCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

function Afsadmin(props) {
  const [form] = Form.useForm();
  const [, , removeCookie] = useCookies(["my_token"]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dashboardType, setDashboardType] = useState(null);
  const [currToggle, setCurrToggle] = useState(false);
  const navigate = useNavigate();

  const initialAliasData = {
    user: "",
    pass: "",
    timezone: "IST",
    curr1: "",
    curr2: "",
    internal_user: false,
    revShare: 100,
    default_account: "",
  };

  const [aliasData, setAliasData] = useState(initialAliasData);

  const addUnit = async () => {
    setLoadingScreen(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ADSENSE_URL}/user/createUser`,
        {
          username: aliasData.user,
          password: aliasData.pass,
          timezone: aliasData.timezone,
          currency: [aliasData.curr1, aliasData.curr2],
          type: dashboardType,
          internal_user: aliasData.internal_user,
          revShare: aliasData.revShare,
          default_account: aliasData.default_account,
        }
      );

      // Reset form and aliasData state
      setAliasData(initialAliasData);
      form.resetFields();
      setCurrToggle(false);
      setOpenModal(false);
      alert(res.data.message);
    } catch (error) {
      console.error("Error creating user:", error);
    } finally {
      setLoadingScreen(false);
    }
  };

  const handleLogout = () => {
    removeCookie("my_token");
    navigate("/login");
  };

  const handleFormChange = (changedFields) => {
    const [field] = changedFields;
    setAliasData((prev) => ({
      ...prev,
      [field.name[0]]: field.value,
    }));
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          paddingInline: "30px",
          display: "flex",
          alignItems: "center",
          background: "#1976D2",
          justifyContent: "space-between",
          width: "100%",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
          height: "10vh",
        }}
      >
        <Text className="text-white text-xl font-normal">Unibots</Text>
        <div className="flex justify-center">
          <Button
            danger
            type="text"
            size="large"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      </Header>
      <Content style={{ padding: "24px", background: "#fff" }}>
        {/* <div className="flex justify-between items-center">
          <Title level={4} style={{ margin: 0 }}>
            Create Adsense dashboards
          </Title>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setDashboardType("ADSENSE");
                setOpenModal(true);
              }}
              style={{ marginRight: "8px" }}
            >
              Add Adsense User
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setDashboardType("QUIZTWIZ");
                setOpenModal(true);
              }}
              style={{ marginRight: "8px" }}
            >
              Add Quiztwiz User
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setDashboardType("AFS");
                setOpenModal(true);
              }}
            >
              Add AFS User
            </Button>
          </div>
        </div> */}

        <div className="p-4 flex flex-col items-center">
          <div className="w-full flex flex-col md:flex-row md:justify-between items-center">
            <Title
              level={4}
              style={{ margin: 0 }}
              className="text-center md:text-left"
            >
              Create Adsense dashboards
            </Title>
            <div className="flex flex-col md:flex-row mt-4 md:mt-0 space-y-2 md:space-y-0 md:space-x-2">
              <Button
                type="primary"
                onClick={() => {
                  setDashboardType("ADSENSE");
                  setOpenModal(true);
                }}
              >
                Add Adsense User
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setDashboardType("QUIZTWIZ");
                  setOpenModal(true);
                }}
              >
                Add Quiztwiz User
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setDashboardType("AFS");
                  setOpenModal(true);
                }}
              >
                Add AFS User
              </Button>
            </div>
          </div>
        </div>

        <Modal
          open={openModal}
          title={`Create ${dashboardType} User`}
          onCancel={() => {
            setAliasData(initialAliasData);
            form.resetFields();
            setOpenModal(false);
          }}
          footer={null}
          closeIcon={<CloseCircleOutlined />}
        >
          <Spin spinning={loadingScreen}>
            <Form
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 15,
              }}
              // initialValues={aliasData}
              onFieldsChange={handleFormChange}
              onFinish={addUnit}
            >
              {["ADSENSE", "QUIZTWIZ", "AFS"].includes(dashboardType) && (
                <div>
                  <Form.Item
                    label="Username"
                    name="user"
                    rules={[
                      { required: true, message: "Please input username!" },
                    ]}
                  >
                    <Input placeholder="Enter Username" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="pass"
                    rules={[
                      { required: true, message: "Password is required!" },
                      {
                        validator: (_, value) => {
                          if (value && value.length < 8) {
                            return Promise.reject(
                              new Error(
                                "Password must be at least 8 characters long."
                              )
                            );
                          } else if (
                            value &&
                            value.length >= 8 &&
                            !/\d/.test(value)
                          ) {
                            return Promise.reject(
                              new Error(
                                "Password must contain at least one number."
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter Password" />
                  </Form.Item>
                </div>
              )}

              {["AFS", "QUIZTWIZ"].includes(dashboardType) && (
                <>
                  <Form.Item
                    label="Timezone"
                    name="timezone"
                    rules={[
                      { required: true, message: "Please input timezone!" },
                    ]}
                  >
                    <Select placeholder="Select Timezone">
                      <Option value="IST">IST</Option>
                      <Option value="billing">PST</Option>
                    </Select>
                  </Form.Item>
                  {["AFS", "QUIZTWIZ"].includes(dashboardType) && (
                    <Form.Item label="Currency Convert" valuePropName="checked">
                      <Switch
                        checked={currToggle}
                        onChange={() => setCurrToggle(!currToggle)}
                      />
                    </Form.Item>
                  )}
                </>
              )}

              {currToggle && ["AFS", "QUIZTWIZ"].includes(dashboardType) && (
                <>
                  <Form.Item
                    label="Currency 1"
                    name="curr1"
                    rules={[
                      {
                        required: true,
                        message: "Please select currency!",
                      },
                    ]}
                  >
                    <Select placeholder="Currency 1">
                      <Option value="USD">USD</Option>
                      <Option value="AED">AED</Option>
                      <Option value="INR">INR</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Currency 2"
                    name="curr2"
                    rules={[
                      {
                        required: true,
                        message: "Please select currency!",
                      },
                    ]}
                  >
                    <Select placeholder="Currency 2">
                      <Option value="USD">USD</Option>
                      <Option value="AED">AED</Option>
                      <Option value="INR">INR</Option>
                    </Select>
                  </Form.Item>
                </>
              )}

              {["AFS", "QUIZTWIZ"].includes(dashboardType) && (
                <>
                  {"AFS".includes(dashboardType) && (
                    <Form.Item label="Internal User" name="internal_user">
                      <Select placeholder="Is User Internal?">
                        <Option value={true}>true</Option>
                        <Option value={false}>false</Option>
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Rev Share"
                    name="revShare"
                    rules={[
                      {
                        required: true,
                        message: "Please enter rev share!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter revShare" type="number" />
                  </Form.Item>
                </>
              )}

              {["AFS"].includes(dashboardType) && (
                <Form.Item
                  label="Default Account"
                  name="default_account"
                  value={aliasData.default_account}
                  rules={[
                    {
                      required: true,
                      message: "Please select default account!",
                    },
                  ]}
                >
                  <Select placeholder="Select Default Account">
                    <Option value="SIP">SIP</Option>
                    <Option value="POA">POA</Option>
                  </Select>
                </Form.Item>
              )}

              <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </Content>
    </Layout>
  );
}

export default Afsadmin;
